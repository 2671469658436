// Размер шрифта заголовков
$header-font-size: 40px;

// Размер шрифта текста
$text-font-size: 50px;

// Радиус движения глитча №3 вокруг букв
$glitch3-move-radius: $text-font-size/25;


.animate {
	animation-duration: 3s;
	animation-name: fadeInLeft, rubberBand;
}

@keyframes fadeInLeft {
	from {
		opacity: 0;
		transform: translate3d(-100%, 25%, 0);
	}

	to {
		opacity: 1;
		transform: translate3d(0, 0, 0);
	}
}

.wrap-glitch {
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
}

.glitch1 {
	color: var(--main-text-color);
	font-family: sans-serif;
	font-weight: 800;
	position: relative;
	font-size: $header-font-size;
	padding: 30px 30px 15px;

	&:before, &:after {
		width: 100%;
		content: attr(data-text);
		color: #fff;
		position: absolute;
		top: 0;
		overflow: hidden;
		padding: 30px;
	}

	&:before {
		left: 3px;
		text-shadow: -3px 0 red;
		animation: glitch-before 2s linear 0s infinite alternate;
	}

	&:after {
		left: -3px;
		text-shadow: -3px 0 blue;
		animation: glitch-after 2s linear 0s infinite alternate;
	}
}

@keyframes glitch-before {
	$steps: 20;
	@for $i from 0 through $steps {
		#{percentage($i*(1/$steps))} {
			clip: rect(random(150)+px, 9999px, random(150)+px, 30px)
		}
	}
}

@keyframes glitch-after {
	$steps: 20;
	@for $i from 0 through $steps {
		#{percentage($i*(1/$steps))} {
			clip: rect(random(150)+px, 9999px, random(150)+px, 30px)
		}
	}
}

.glitch2 {
	color: var(--main-text-color);
	font-family: sans-serif;
	font-weight: 600;
	position: relative;
	font-size: $header-font-size;
	width: 100%;
	padding: 30px 30px 15px;
	margin: 0 auto;
	text-align: center;
	text-transform: uppercase;
	letter-spacing: 0.01em;
	transform: scale3d(1, 1, 1);


	&::before,
	&::after {
		content: attr(data-text);
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		overflow: hidden;
		background: #333;
		color: #fff;
		clip: rect(0, 900px, 0, 0);

	}

	&::before {
		left: 7px;
		text-shadow: 1px 0 green;
		animation: glitch-effect 3s infinite linear alternate-reverse;
	}

	&::after {
		left: 3px;
		text-shadow: -1px 0 red;
		animation: glitch-effect 2s infinite linear alternate-reverse;
	}
}

$steps: 20;
@keyframes glitch-effect {
	@for $i from 0 through $steps {
		#{percentage($i*(1/$steps))} {
			clip: rect(random(100)+px, 9999px, random(100)+px, 0);
		}
	}

}

.glitch3 {
	color: var(--main-text-color);
	font-family: sans-serif;
	font-weight: 600;
	position: relative;
	font-size: $text-font-size;
	padding: 30px 30px 15px;
	margin: 0 auto;
	text-align: center;
	//text-transform: uppercase;
	letter-spacing: 0.01em;
	animation: move linear 2000ms infinite;
}

@media (max-width: 768px) {
	.glitch3 {
		font-size: 24px;
	}
}

@keyframes move {
	0% {
		text-shadow: $glitch3-move-radius -$glitch3-move-radius 0 hsla(0, 100%, 50%, 1),
		$glitch3-move-radius/2 -$glitch3-move-radius/2 0 hsla(0, 100%, 50%, 1),
		-$glitch3-move-radius $glitch3-move-radius 0 hsla(180, 100%, 50%, 1),
		-$glitch3-move-radius/2 $glitch3-move-radius/2 0 hsla(180, 100%, 50%, 1);
	}
	25% {
		text-shadow: -$glitch3-move-radius -$glitch3-move-radius 0 hsla(180, 100%, 50%, 1),
		-$glitch3-move-radius/2 -$glitch3-move-radius/2 0 hsla(180, 100%, 50%, 1),
		$glitch3-move-radius $glitch3-move-radius 0 hsla(0, 100%, 50%, 1),
		$glitch3-move-radius/2 $glitch3-move-radius/2 0 hsla(0, 100%, 50%, 1);
	}
	50% {
		text-shadow: -$glitch3-move-radius $glitch3-move-radius 0 hsla(0, 100%, 50%, 1),
		-$glitch3-move-radius/2 $glitch3-move-radius/2 0 hsla(0, 100%, 50%, 1),
		$glitch3-move-radius -$glitch3-move-radius 0 hsla(180, 100%, 50%, 1),
		$glitch3-move-radius/2 -$glitch3-move-radius/2 0 hsla(180, 100%, 50%, 1);
	}
	75% {
		text-shadow: $glitch3-move-radius $glitch3-move-radius 0 hsla(180, 100%, 50%, 1),
		$glitch3-move-radius/2 $glitch3-move-radius/2 0 hsla(180, 100%, 50%, 1),
		-$glitch3-move-radius -$glitch3-move-radius 0 hsla(0, 100%, 50%, 1),
		-$glitch3-move-radius/2 -$glitch3-move-radius/2 0 hsla(0, 100%, 50%, 1);
	}
	100% {
		text-shadow: $glitch3-move-radius -$glitch3-move-radius 0 hsla(0, 100%, 50%, 1),
		$glitch3-move-radius/2 -$glitch3-move-radius/2 0 hsla(0, 100%, 50%, 1),
		-$glitch3-move-radius $glitch3-move-radius 0 hsla(180, 100%, 50%, 1),
		-$glitch3-move-radius/2 $glitch3-move-radius/2 0 hsla(180, 100%, 50%, 1);
	}
}
