:root {
	--main-bg-color: #fff;
	--main-text-color: #212529;
	--main-button-color: #212529;
	--main-button-text: #fff;
	--main-border-footer: 1px solid rgba(0, 0, 0, 0.1);
}

.dark-mode {
	--main-bg-color: #333;
	--main-text-color: #fff;
	--main-button-color: #fff;
	--main-button-text: #212529;
	--main-border-footer: 1px solid rgba(255, 255, 255, 0.1);
}
a {
	color: #007bff;
	text-decoration: none;
	background-color: transparent;
	transition: .3s;
}

a:hover {
	color: #62a5f1;
	text-decoration: underline;
}
header {
	text-align: center;
	display: flex;
	justify-content: center;
	align-items: center;
	margin-top: 0.5rem;

	& > * + * {
		margin-left: 0.5rem;
	}
}

@media (max-width: 768px) {
	header {
		flex-direction: column;

		& > * + * {
			margin-left: 0;
		}
	}
}

.mute {
	opacity: 0.5;
}

body {
	background: var(--main-bg-color);
	color: var(--main-text-color);
	transition: background, color ease-in-out 0.3s;
}

.chat {
	span {
		display: inline-block;
		min-width: 0.3rem;
	}
}

.flex-reverse {
	display: flex;
	flex-direction: column-reverse;
}

.demo-text {
	text-align: center;
}

.message {
	text-align: center;
	font-size: 150%;
	margin: 0.5rem 0;
	position: relative;

	&.emoji-only {
		font-size: 400%;
	}
}

.message-time {
	display: none;
}

.switch-button {
	background: var(--main-button-color);
	display: inline-block;
	border-radius: 50%;
	width: 40px;
	height: 40px;
	margin-left: 2rem;
	border: none;
	color: var(--main-button-text);
	transition: background, color ease-in-out 0.3s;
}

.footer {
	opacity: 0.5;
	text-align: center;
	margin: 1rem 0;
	padding: 0.5rem 0;
	border-top: var(--main-border-footer);
}

.sijeko-link {
	&:before {
		content: ' ';
		display: inline-block;
		width: 1rem;
		height: 1rem;
		margin: 0 0.2rem 0 0;
		background-image: url('https://sijeko.ru/assets/templates/v4/img/favicon/160.png');
		background-repeat: no-repeat;
		background-size: cover;
	}
}

.dot-divider {
	margin: 0 0.5rem;
}
